
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import router from "src/router";
import { ReportService } from "src/services";
import { Toast } from "vant";
import patterns from "src/utils/patterns";
import eventBus from 'src/views/ai/utils/bus';
import globalConfig from "src/config/index";
import pageBack from "src/components/page-back";
import dayjs from "dayjs";

@Component({
    components:
    {
        pageBack
    }
})
export default class ReportDecode extends Vue
{
    // 是否预约成功
    private appointmentShow:boolean = false;

    // 是否已提交预约
    private isSumbitMake: boolean = false;

    // 已预约时间
    private intendedCallTime: string = "";

    // 是否显示切换用户弹窗
    private showUserPopup: boolean = false;

    // 是否显示选择时间弹窗
    private showTimePop: boolean = false;

    // 日期数组
    private dateModel: Array<any> = [];

    // 时间数组
    private timeModel: Array<any> = [];

    // 报告id
    private get reportId(): any
    {
      return this.$route.query?.reportId || null;
    }

    // 新的联系人
    private currentUser: any = {};

    // 数据
    private modelData: any = {
        attachments: [],
        employerMemberId: null,
        id: null,
        isAnalyze: null,
        isRead: false,
        isSumbitAnalyze: false,
        memberId: null,
        memberName: "",
        orderId: null,
        orderNo: "",
        orgId: null,
        orgName: "",
        packageId: null,
        packageName: "",
        physicalDate: "",
        reportFileType: 1,
        reportFileTypeStr: "",
        reportKind: null,
        reportKindStr: "",
        reportName: "",
        canProceedUpload: true,
        status: null
    };

    // 日期表单
    private dateForm: any = {
        // 选择的时间
        reservationDate: "",
        reservationTimeSlot: null,
        reservationTimeSlotStr: "",
    };

    // 时间表单
    private dateFormNew: any = {
        // 最终选择时间
        reservationDate: "",
        reservationTimeSlot: null,
        reservationTimeSlotStr: "",
    };
    
    /**
     * 当前选中时间
     * @private
     * @returns void
     */
    private get currentReservationDate(): any
    {
        let year = this.dateFormNew.reservationDate.substring(5,7)+'月';
        let month = this.dateFormNew.reservationDate.substring(8,10)+'日';
        return year + month
    }

    /**
     * 跳转报告上传
     * @protected
     * @returns 
     */
    private onActionUpLoad(): void {
        router.push({name: "reportAppointmentUpload", query: {id: this.currentUser.employerMemberId, name: this.currentUser.memberName}})
    };

    /**
     * 获取体检报告详情
     * @private
     * @returns void
     */
     private async getPhysicalReportDetail(): Promise<void>
    {
        try
        {
            let {content: result} =  await ReportService.instance.physicalReportDetail(this.reportId);
            
            if(result.data)
            {
                this.modelData = Object.assign({}, result.data);
                // 重新赋值当前人员
                if (result.data.employerMemberId)
                {
                  let value = this.userList.find(res => res.employerMemberId == result.data.employerMemberId) || null;
                  if (value)
                  {
                    this.currentUser = this.userList.find(res => res.employerMemberId == result.data.employerMemberId);
                  }
                }
                if (this.modelData.status == 1 && this.modelData.isSumbitAnalyze) {
                    // 已预约未解读
                    this.getReservationExplain();
                    this.isSumbitMake = true;
                } else {
                    this.isSumbitMake = false;
                }
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 切换报告
     * @private
     * @returns void
     */
    private replacementReport() {
        router.replace({name:'reportReplacementReport', query:{id: this.reportId + "", employerMemberId: this.currentUser.employerMemberId}});
    }

    /**
     * 获取预约解读详情
     * @private
     * @returns void
     */
    private async getReservationExplain() {
        try {
            let {content: result} = await ReportService.instance.getReservationExplain(this.reportId);
            if (result.data) {
                this.currentUser.phone = result.data.phone || "";
                this.currentUser.age = result.data.age || 0;
                this.currentUser.familyTypeStr = result.familyTypeStr || result.data.memberTypeStr || "";
                this.currentUser.memberName = result.data.memberName || "";
                this.currentUser.memberTypeStr = result.data.memberTypeStr || "";
                this.currentUser.employerMemberId = result.data.employerMemberId || null;
                this.currentUser.memberId = result.data.memberId || null;
                this.currentUser.marriageStatusStr = result.data.marriageStatusStr || "";
                this.dateFormNew.reservationTimeSlot = true;
                this.intendedCallTime = result.data.intendedCallTime || "";
            }
        } catch (err) {
            Toast(err);
        }
    };

    /**
     * 获取可约天数数据源
     * @private
     * @returns void
     */
    private async getTimeSlotData() {
        try {
            let {content: result} = await ReportService.instance.getTimeSlotData();
            if (result?.data?.length) {
                this.dateModel = result.data;
                this.dateForm.reservationDate = result.data[0].reservationTime;
                if (this.timeModel.length == 0) {
                    this.getTimeSlotQuota();
                }
            }
        } catch (err) {
            Toast(err);
        }
    }
    /**
     * 根据时间段获取名额
     * @private
     * @returns void
     */
    async getTimeSlotQuota() {
        try {
            let {content: result} = await ReportService.instance.getTimeSlotQuota({
                dateTime: this.dateForm.reservationDate,
                reportId: this.reportId,
            });
            if (result.data) {
                this.timeModel = result.data.timeSlotQuota || [];
            }
        } catch (err) {
            Toast(err);
        }
    };
    // 选择时间 -- 弹窗
    private onCheckDatePop() {
        if (this.isSumbitMake) {
            return;
        }
        this.dateForm.reservationDate = this.dateFormNew.reservationDate;
        this.dateForm.reservationTimeSlot = this.dateFormNew.reservationTimeSlot;
        this.dateForm.reservationTimeSlotStr = this.dateFormNew.reservationTimeSlotStr;
        this.getTimeSlotData();
        this.showTimePop = true;
    };
    // 选择日期
    private onCheckDate(data) {
        if (data.quota > 0) {
            this.dateForm.reservationDate = data.reservationTime;
            this.dateForm.reservationTimeSlot = null;
            this.getTimeSlotQuota();
        }
    };
    // 选择时间
    private onTimeDate(data) {
        if (data.quota > 0) {
            this.dateForm.reservationTimeSlot = data.reservationTimeSlot;
            this.dateForm.reservationTimeSlotStr = data.reservationTimeSlotStr;
        }
    };
    // 确认选择时间按钮
    private onCheckDateBtn() {
        if (this.dateForm.reservationTimeSlot == null) {
            Toast("请选择有效通话时间！");
        } else {
            this.dateFormNew.reservationDate = this.dateForm.reservationDate;
            this.dateFormNew.reservationTimeSlot =
            this.dateForm.reservationTimeSlot;
            this.dateFormNew.reservationTimeSlotStr =
            this.dateForm.reservationTimeSlotStr;
            this.showTimePop = false;
        }
    }

    // 预约解读
    private async onMakeDecode() {
      try {
        if (this.dateFormNew.reservationTimeSlot == null) {
            Toast("请选择有效通话时间!")
            return;
        }
        if (!patterns.mobile.test(this.currentUser.phone)) {
            Toast("请输入正确手机号码!");
            return;
        }
        if(!this.reportId) {
            Toast("请先上传报告!");
            return;
        }
        let params = {
          phone: this.currentUser.phone,
          reportId: this.reportId,
          reservationDate: this.dateFormNew.reservationDate,
          reservationTimeSlot: this.dateFormNew.reservationTimeSlot,
        };
        await ReportService.instance.reservationExplain(params);
        this.appointmentShow = true;
        this.getPhysicalReportDetail();
        } catch (err) {
            Toast(err);
        }
    }
    // 取消预约解读
    private async cancelReservation() {
        try {
            await ReportService.instance.cancelReservation(this.reportId);
            // 日期表单
            this.dateForm = {
                // 选择的时间
                reservationDate: "",
                reservationTimeSlot: null,
                reservationTimeSlotStr: "",
            };
            // 时间表单
            this.dateFormNew = {
                // 最终选择时间
                reservationDate: "",
                reservationTimeSlot: null,
                reservationTimeSlotStr: "",
            };
            Toast("已取消预约");
            this.getPhysicalReportDetail();
        } catch (err) {
            Toast(err);
        }
    };

    /**
     * 是否已经身份验证
     * @private
     * @returns void
     */
    private identityChecked: boolean = false;

    /**
     * 确认客户是否已经验证过是本人
     * @private
     * @returns void
     */
    private async checkPrivacyTerm(): Promise<void> {
        try {
            let {content:result} = await ReportService.instance.checkIdentity();
            this.identityChecked = !!result.data.checked;
            if (this.identityChecked)
            {
                if (this.reportId)
                {
                    this.getPhysicalReportDetail();
                }
                else
                {
                    this.getNewReport();
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * 跳转校验页面- 添加报告
     * @private
     * @returns void
     */
    private onAddReport(): void {
        router.push({ name: "identityVerify" })
    }

    /**
     * 获取用户列表
     * @protected
     * @returns Array<any>
     */
    protected userList: Array<any> = [];

    /**
     * 获取用户列表
     * @private
     * @returns void
     */
     protected getUserList(userList: any): void
    {
      this.userList = userList;
      let data = userList.length && userList[0] || {};
      this.currentUser = Object.assign({}, data);
      this.checkPrivacyTerm();
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
     protected onConfirmUser(data: any): void
    {
        this.currentUser = Object.assign({}, data);
        this.showUserPopup = false;
        // 日期表单
        this.dateForm = {
            // 选择的时间
            reservationDate: "",
            reservationTimeSlot: null,
            reservationTimeSlotStr: "",
        };
        // 时间表单
        this.dateFormNew = {
            // 最终选择时间
            reservationDate: "",
            reservationTimeSlot: null,
            reservationTimeSlotStr: "",
        };
        this.getNewReport();
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 获取最新一条报告 
     */
     protected async getNewReport(): Promise<any> {
        try {
            let { content: result } = await ReportService.instance.getPhysicalReportList(
                {employerMemberId: this.currentUser.employerMemberId}
            );
            if (result.data)
            {
                if (result.data.reportGroups.length)
                {
                    if (result.data.reportGroups[0].reports.length)
                    {
                        if (result.data.reportGroups[0].reports[0].id)
                        {
                          this.$router.replace({name: "reportDecode", query: {reportId: result.data.reportGroups[0].reports[0].id + ""}});
                          this.$router.go(0);
                        }
                    }
                }
                else
                {
                    this.modelData = {};
                }
            }
        } catch (err) {
            Toast(err);
        }
    }
}
