
 import { Component, Vue } from "vue-property-decorator";
 import { ReportService, UserService } from "src/services";
 import { Toast } from "vant";
 import { tools } from "src/utils";
 import { ILogin } from "src/model";
 import { DesSecretUtils } from "src/utils";
 import router from "src/router";
 import globalConfig from "src/config/index";
 import pageBack from "src/components/page-back";
 import UAuthorization from "../authorization.vue";
 const showRightsCode = globalConfig.showRightsCode;
import agreement from "../agreement.vue";
import zfagreement from "../zf-agreement.vue";
import rightsAgreement from "../rights-agreement.vue";

 Component.registerHooks([
        "beforeRouteEnter",
        "beforeRouteLeave",
        "beforeRouteUpdate" // for vue-router 2.2+
 ]);
 
 @Component({
    components:
    {
        pageBack,
        UAuthorization,
        agreement,
        rightsAgreement,
        zfagreement,
    }
})
 export default class ReportLogin extends Vue
 {

    /**
     * 显示协议弹框
     * @private
     * @returns boolean
     */
    private showOverlay: boolean = false;

    /**
     * 是否显示服务协议
     * @private
     * @returns boolean
     */
    private showAgreement: boolean = false;

    /**
     * 是否显示中福服务协议
     * @private
     * @returns boolean
     */
     private showZfAgreement: boolean = false;

    /**
     * 是否显示权益隐私协议
     * @private
     * @returns boolean
     */
    private showRightsAgreement: boolean = false;


    /* 跳转用户协议
     * @private
     * @returns void
     */
    private onAgreement(): void
    {
       this.showAgreement = true;
    }

    /* 关闭用户协议
     * @private
     * @returns void
     */
    private closeAgreement(): void
    {
        this.showAgreement = false;
    }

    /* 关闭中福服务协议
     * @private
     * @returns void
     */
     private closeZfAgreement(): void
    {
        this.showZfAgreement = false;
    }

     /* 跳转中福服务协议
        * @private
        * @returns void
        */
     private onzfAgreement(): void
    {
       this.showZfAgreement = true;
    }

    /* 跳转携保权益协议
     * @private
     * @returns void
     */
    private onRightsAgreement(): void
    {
       this.showRightsAgreement = true;
    }

    /* 关闭携保权益协议
     * @private
     * @returns void
     */
    private closeRightsAgreement(): void
    {
       this.showRightsAgreement = false;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get showRights(): any
    {
        return showRightsCode.includes(localStorage.getItem("appid"));
    }

    /**
     * 上个页面路由名称
     * @private
     * @returns string
     */
     private beforeRouteName: string = ""

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next)
    {
        
        next((vm)=> {
            vm.beforeRouteName = from.name
        })

    }


    
    /**
     * 登录数据模型
     * @private
     * @returns any
     */
    private modelData: any =
    {
        employerMemberId: 0,
        name: "",
        phone: "",
        code: "",
        reportId:null
    };

    /**
     * 点击获取验证码等60秒完成后才可以重新点击发送
     * @private
     * @returns boolean
     */
    private isDisabled: boolean = false;

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
     protected showUserPopup: boolean = false;

    /**
     * 是否同意协议
     * @private
     * @returns boolean
     */
     private checkedAgreement: boolean = false;

    /**
     * 获取验证码
     * @private
     * @returns string
     */
    private sendCode: string = "获取验证码";

    /**
     * 获取验证码60秒
     * @private
     * @returns string
     */
    private time: any = 60;

    /**
     * 是否显示用户授权协议
     * @private
     * @returns boolean
     */
    private showAuthorization: boolean = false;

    /**
     * 查询报告数据
     * @private
     * @returns boolean
     */
    private reportData:any = []
 
    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /* 关闭用户授权协议
    * @private
    * @returns void
    */
    private closeAuthorization() {
        this.showAuthorization = false
    }

    /* 是否显示弹窗
    * @private
    * @returns void
    */
    private showUserChange() {
        if(!this.modelData.reportId) {
            this.showUserPopup = true
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): any
    {
        
        let queryParams = this.$store.getters.getReportParams || {};
        this.modelData.employerMemberId = queryParams.id
        this.modelData.name = queryParams.name
        this.modelData.phone = queryParams.phone
        this.modelData.reportId = this.$route.query.id
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
     protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 用户授权协议
     * @private
     * @returns void
     */
        private onAuthorization(): void {
            this.showAuthorization = true;
        }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
     protected onConfirmUser(data: any,flag:any): void
    {
        if(!flag) {
            this.modelData.employerMemberId = data.employerMemberId
            this.modelData.name = data.memberName
            this.modelData.phone = data.phone
            this.showUserPopup = false
        }

    }

    /**
     * 查询报告
     * @private
     * @returns void
     */
    private async onQueryReport(): Promise<void>
    {
        try {
            let {content:result} = await ReportService.instance.queryUnAuth(this.modelData)
            this.reportData = result.data
            if(this.reportData && this.reportData.length) {
                localStorage.setItem("reportData",JSON.stringify(result.data))
                router.replace({name:"addReport",query:{id:this.modelData.reportId}})
            }
            else {
                Toast('未查询到报告哦~')
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 查询报告
     * @private
     * @returns void
     */
     private async queryReport(): Promise<void>
    {
        try
        {
            if(!this.checkedAgreement)
            {
                this.showOverlay = true;
                return;
            }
            this.onQueryReport();
        }
        catch(err)
        {
            Toast(err);
        }
    }

    

    /* 同意协议
     * @private
     * @returns void
     */
    private async onAgree ()
    {
        this.checkedAgreement = true;
        this.showOverlay = false;
        // 点击同意之后查询
        try
        {
            this.onQueryReport();
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 获取验证码
     * @private
     * @returns void
     */
     private async onGetModifyCode(): Promise<void>
    {
        try
        {
            if(!this.modelData.employerMemberId)
            {
                Toast({
                    duration: 1000,
                    message: "请选择体检人"
                });

                return;
            }

            if(!this.modelData.phone || this.modelData.phone.length != 11)
            {
                Toast({
                    duration: 1000,
                    message: "请输入11位手机号"
                });

                return;
            }

            let data =
            {
                employerMemberId: this.modelData.employerMemberId,
            };

            await ReportService.instance.sendQueryUnAuth(data)
            Toast("验证码发送成功");
            this.isDisabled = true;
            let interval = window.setInterval(() =>
            {
                this.sendCode = `${this.time}s`;
                --this.time;
                if(this.time < 0)
                {
                    this.sendCode = "重新发送";
                    this.time = 60;
                    this.isDisabled = false;
                    window.clearInterval(interval);
                }
            },1000);
        }
        catch(err)
        {
            Toast(err);
        }

    }
 
     
     
 }
 