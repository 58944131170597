
import { Component, Vue,Watch } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import dayjs from "dayjs";
import { bankService, employerReimbursementService } from "src/services";
import branList from './bank-list.vue'
import globalConfig from "src/config/index";

Component.registerHooks([
        "beforeRouteEnter",
        "beforeRouteLeave",
        "beforeRouteUpdate" // for vue-router 2.2+
 ]);


@Component({
    components:{
        branList
    }
})
export default class UserSetting extends Vue
{

    /**
     * 收款类型
     * @protected
     */
    protected collectionTypeList: Array<any> = 
        [
            {
                id: 0,
                name: "支付宝"
            },
            {
                id: 1,
                name: "银行卡"
            }
    ];

    
    /**
     * 是否展示银行列表
     */
     private showBankList:boolean = false
    
    /**
     * form表单
     * @protected
     */
    protected formData: any =
    {
        PhysicalDateTime: "",
        Amount: null,
        PaymentType: 0,
        PaymentTypeName:"支付宝",
        PaymentName: "",
        PaymentAccount: "",
        BankName:""
    }

    /**
     * 上个页面路由名称
     * @private
     * @returns string
     */
     private beforeRouteName: string = ""

     private flag: boolean = false

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next)
    {
        
        next((vm)=> {
            vm.beforeRouteName = from.name
        })

    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }


    /**
     * 申请失败
     * @protected
     */
    protected applyFailed: boolean = false;

    /**
     * 失败原因
     * @protected
     */
    protected faileTitle: string = "";

    
    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 上传文件
     * @protected
     * @reyurns Array<any>
     */
    protected uploader: Array<any> = []


    protected receiveBankList:  Array<any> = []

    protected onCloseBankList (brankName) {
        this.showBankList = false
        this.formData.BankName = brankName;
    }

    /**
     * 上传文件
     * @private
     * @returns Promise<void>
     */
    private async onUploadFile(): Promise<void>
    {
        if(!this.formData.PhysicalDateTime)
        {
            Toast("请先选择体检日期!");
            return;
        }
        if(!this.formData.Amount.trim())
        {
            Toast("请先输入报销金额!");
            return;
        }
        if(!this.formData.PaymentName.trim())
        {
            Toast("请先输入收款方姓名!");
            return;
        }
        if(!this.formData.PaymentAccount.trim())
        {
            Toast("请先输入账号!");
            return;
        }

        if(this.formData.PaymentType == 1 && !this.formData.BankName) {
            Toast("请先输入银行名称!");
            return;
        }

        if (this.uploader.length > 0)
        {
            try
            {
                let formData = new FormData();
                Object.keys(this.formData).forEach(item=>
                {
                    formData.append(item, this.formData[item]);
                })
                this.uploader.forEach(item=>{
                    formData.append("Pictures", item.file)
                });
                this.onSubmit(formData)
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
        }
        else
        {
            Toast("请先上传发票图片!");
            return;
        }
    }


    /**
     * 卡号识别银行
     * @private
     * @returns Promise<void>
     */
    private async onBankCardBlur() {
        try {
            let {content:result} = await bankService.instance.getBankName(this.formData.PaymentAccount)
            this.formData.BankName = result.data

        } catch (error) {
            this.formData.BankName = ""
            Toast(error)
        }
    }


    /**
     * 收款银行列表
     * @private
     * @returns Promise<void>
     */
    public onReceivingBank() {
        // this.$router.push({name: "banklist"})
        this.showBankList = true
    }


    /**
     * 提交
     * @private
     * @returns Promise<void>
     */
    private async onSubmit(formData): Promise<void>
    {
        try
        {
            await employerReimbursementService.instance.submitReimbursement(formData);
            Toast("提交成功!");
            this.$router.go(-1);
            
        }
        catch(err)
        {
            // Toast(JSON.stringify(err))
            this.faileTitle = err
            this.applyFailed = true
        }
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string): void
    {
        this.$router.push({name});
    }

    /**
     * 体检时间弹框
     * @private
     * @returns boolean
     */
    private showDate: boolean = false;

    /**
     * 合同选择弹窗
     * @private
     * @returns boolean
     */
    private showContract: boolean = false;

    /**
     * 收款类型弹窗
     * @private
     * @returns boolean
     */
    private showCollectType: boolean = false;



    /**
     * 日期控件
     * @private
     * @returns Date
     */
    private minDate: Date = new Date(1900, 1, 1);

    /**
     * 最大时间
     * @private
     * @returns Date
     */
    private maxDate: Date = dayjs().toDate();

    /**
     * 日期时间
     * @private
     * @returns Date
     */
    private date: Date = dayjs().toDate();

    /**
     * 确定日期选择
     * @private
     * @returns void
     */
    private onDateConfirm(date: any): void
    {
        if(date)
        {
            this.formData.PhysicalDateTime = dayjs(date).format("YYYY/MM/DD");
        }
        
        this.showDate = false;
    }

    /**
     * 取消日期选择
     * @private
     * @returns void
     */
    private onDateCancel(): void
    {
        this.showDate = false;
    }
    

    /**
     * 确认收款类型
     * @private
     * @returns void
     */
    private onConfirmCollect(data) {
        this.formData.PaymentType = data.id
        this.formData.PaymentTypeName = data.name
        this.onCancelCollect()
    }


    /**
     * 取消收款类型
     * @private
     * @returns void
     */
    private onCancelCollect() {
        this.showCollectType = false
    }




}
