var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"center-set",class:{'page-back-head': !(_vm.employerConfig && _vm.employerConfig.hidTitle)}},[(!(_vm.employerConfig && _vm.employerConfig.hidTitle))?_c('u-page-back',{attrs:{"title":"发票报销"}}):_vm._e(),_c('div',{staticClass:"tip hea-cl"},[_vm._v("*请如实填写以下信息，以免造成转账失败")]),_c('div',{staticClass:"center-info"},[_c('van-form',[_c('van-field',{attrs:{"label-class":"label-css","required":"","label":"体检日期","input-align":"right","value":_vm.formData.PhysicalDateTime,"readonly":true,"is-link":"","error-message-align":"right","placeholder":"请选择体检时间"},on:{"click":function($event){_vm.showDate = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"选择年月日","minDate":_vm.minDate,"maxDate":_vm.maxDate},on:{"confirm":_vm.onDateConfirm,"cancel":_vm.onDateCancel},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('van-field',{attrs:{"required":"","type":"number","label":"报销金额","input-align":"right","rules":[
          {required: true, message: '请输入报销金额',trigger: 'onBlur' }
          ],"error-message-align":"right","placeholder":"请输入报销金额"},model:{value:(_vm.formData.Amount),callback:function ($$v) {_vm.$set(_vm.formData, "Amount", $$v)},expression:"formData.Amount"}}),_c('van-field',{attrs:{"label-class":"label-css","label":"收款类型","input-align":"right","value":_vm.formData.PaymentTypeName,"readonly":true,"is-link":"","error-message-align":"right","placeholder":"请选择收款类型"},on:{"click":function($event){_vm.showCollectType = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showCollectType),callback:function ($$v) {_vm.showCollectType=$$v},expression:"showCollectType"}},[_c('van-picker',{attrs:{"title":"请选择","show-toolbar":"","columns":_vm.collectionTypeList,"value-key":"name"},on:{"confirm":_vm.onConfirmCollect,"cancel":_vm.onCancelCollect}})],1),_c('van-field',{attrs:{"required":"","label":"收款方姓名","placeholder":"请输入姓名","input-align":"right","error-message-align":"right","rules":[
          {required: true, message: '请输入姓名',trigger: 'onBlur' }
          ]},model:{value:(_vm.formData.PaymentName),callback:function ($$v) {_vm.$set(_vm.formData, "PaymentName", $$v)},expression:"formData.PaymentName"}}),(_vm.formData.PaymentType == 0)?_c('van-field',{attrs:{"required":"","label-class":"label-css","label":"支付宝账号","placeholder":"请输入支付宝账号","input-align":"right","rules":[
          {required: true, message: '请输入账号',trigger: 'onBlur' }
          ],"error-message-align":"right"},model:{value:(_vm.formData.PaymentAccount),callback:function ($$v) {_vm.$set(_vm.formData, "PaymentAccount", $$v)},expression:"formData.PaymentAccount"}}):_c('div',[_c('van-field',{attrs:{"required":"","label-class":"label-css","label":"收款卡号","placeholder":"请输入银行卡号","input-align":"right","error-message-align":"right","rules":[
                    {required: true, message: '请输入银行卡号',trigger: 'onBlur' }
                ]},on:{"blur":_vm.onBankCardBlur},model:{value:(_vm.formData.PaymentAccount),callback:function ($$v) {_vm.$set(_vm.formData, "PaymentAccount", $$v)},expression:"formData.PaymentAccount"}}),_c('van-field',{attrs:{"label-class":"label-css","label":"收款银行","input-align":"right","value":_vm.formData.BankName,"readonly":true,"is-link":"","error-message-align":"right","placeholder":"请选择收款银行"},on:{"click":_vm.onReceivingBank}})],1),_c('van-field',{staticClass:"form-item",attrs:{"required":"","name":"uploader","label":"发票图片"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-uploader',{staticClass:"image-uploader",attrs:{"max-count":3},model:{value:(_vm.uploader),callback:function ($$v) {_vm.uploader=$$v},expression:"uploader"}},[_c('div',{staticClass:"file-upload"})])]},proxy:true}])})],1)],1),_c('div',{staticClass:"center-btn"},[_c('button',{staticClass:"btn btn-block",on:{"click":_vm.onUploadFile}},[_vm._v("提交申请")])]),_c('van-overlay',{staticClass:"notice-overlay",attrs:{"show":_vm.applyFailed},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"overlay-wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"hea-justifyC"},[_vm._v(_vm._s(_vm.faileTitle))]),_c('div',{staticClass:"overlay-btn btn-list"},[_c('button',{staticClass:"btn-Later van-hairline--surround btn-gradient",on:{"click":function($event){_vm.applyFailed = false}}},[_vm._v("确认")])])])]),(_vm.showBankList)?_c('branList',{on:{"close-banklist":_vm.onCloseBankList}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }