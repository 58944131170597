
import { Component, Prop, Vue  } from "vue-property-decorator";
import pageBack from "src/components/page-back";
import globalConfig from "src/config/index";


@Component({
    components:
    {
        pageBack
    }
})
export default class OrganizationDetail extends Vue
{

    /**
     * 标题显示
     * @private
     * @returns string
     */
    @Prop({type: String, required: true})
    private pdfUrl: string;
    
    /**
     * 返回上个页面
     * @private
     * @returns void
     */
    private goBack(): void
    {
        this.$emit("close")
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

}
